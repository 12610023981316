/* eslint-disable react/prop-types */
import styled from 'styled-components';

export const DisplayBar = styled.div`
  background-color: #6700fd; //#6700fd #06092bd6

  width: 800px; //414
  text-align: left;
  padding: 10px 5px;

  @media only screen and (max-width: 50em) {
    width: 100%; //414
    height: auto;
  }
`;

function PreEditor({ children, className, ...rest }) {
  return (
    <pre className={className} {...rest}>
      {children}
    </pre>
  );
}

export const Editor = styled(PreEditor)`
  /* background-color: #06092b; */
  color: #fff;
  border: 1px solid;
  width: 800px;
  height: calc(${window.innerHeight + 'px'} - 9.5rem);
  text-align: left;
  padding: 5px;

  @media only screen and (max-width: 50em) {
    width: 100%; //414
    height: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props['bottom'] + 'rem'};
  flex-wrap: ${(props) => props['wrap']};
`;

export const Select = styled.select`
  height: 1.7rem;
  border: none;
  border-radius: 3px;
  margin: 0 2px;
  padding: ${(props) => props['padding']};
  text-align: center;
`;

export const RowTom = styled.p`
  text-align: center;
  padding: 4px;
  color: #000;
  cursor: pointer;
  background-color: #e9e9ed;
  border-radius: 2px;
  margin: 2px;
  width: 50px;
  height: 1.4rem;
  font-size: 0.8rem;
  &.disabled {
    color: #999;
    background-color: #cecece;
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
  }
`;

export const Button = styled.button`
  text-align: center;
  padding: 4px 1rem;
  color: #000;
  cursor: pointer;
  background-color: #e9e9ed;
  border-radius: 2px;
  margin: 2px;
  border: none;
  height: 1.75rem;
  font-size: 0.8rem;
  &:hover {
    background-color: #00ff9f;
  }
  &:active {
    transform: scale(1.1);
  }
  &:disabled {
    color: #999;
    background-color: #cecece;
  }
  @media only screen and (max-width: 50em) {
    line-height: 0.5;
  }
`;

export const Input = styled.input`
  width: calc(100% / 12 * ${(props) => props['col']});
  padding: 0 4px;
  height: 1.75rem;
  margin: 0 2px;
  border: none;
  border-radius: 3px;
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;

  & input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    margin-left: 2px;
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #555555;
    border-radius: 3px;
    background-color: white;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
  & span {
    font-size: 1.2rem;
    padding: 0 2px;
  }
`;

export const Table = styled.table``;

// text-align: 'center',
// padding: auto;: '4px',
// color: '#000',
// cursor: 'pointer',
// background-color: '#fff',
// margin: '2px',
// width: '50px',
// height: '40',
// border-radius: '2px',
