/* eslint-disable no-async-promise-executor */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';
import pTypes from 'prop-types';
// import * as C from '../../components/wrapper';
import * as E from './style';
import * as L from './list-style';
import Logo from '../../assets/images/harpa-white.png';

import { FiSearch, FiArrowRight, FiX, FiPlay, FiMinusCircle, FiPlusCircle, FiPause, FiHome, FiEdit2, FiBook } from 'react-icons/fi';

// import Logo from '/img/harpa.png';

import { toast } from 'react-toastify';
import { ajax, serialize } from '../../api/api';

let w = 0;
let scrollStatus = false;
let interval = null;
let timeScroll = 5;

export default function List() {
  const [startArray, setStartArray] = React.useState([]);
  const [harpa, setHarpa] = React.useState(true);
  const [search, setSearch] = React.useState('');
  const [time, setTime] = React.useState(5);
  const [scrollStop, setScrollStop] = React.useState(false);
  const [tom, setTom] = React.useState([]);
  const [optionalTom, setOptionalTom] = React.useState([]);
  const [tonalidades, setTonalidades] = React.useState([
    { tom: 'C', escala: ['C', 'D', 'E', 'F', 'G', 'A', 'B'] },
    { tom: 'C#', escala: ['C#', 'D#', 'F', 'F#', 'G#', 'A#', 'C'] },
    { tom: 'D', escala: ['D', 'E', 'F#', 'G', 'A', 'B', 'C#'] },
    { tom: 'D#', escala: ['D#', 'F', 'G', 'G#', 'A#', 'C', 'D'] },
    { tom: 'E', escala: ['E', 'F#', 'G#', 'A', 'B', 'C#', 'D#'] },
    { tom: 'F', escala: ['F', 'G', 'A', 'Bb', 'C', 'D', 'E'] },
    { tom: 'F#', escala: ['F#', 'G#', 'A#', 'B', 'C#', 'D#', 'F'] },
    { tom: 'G', escala: ['G', 'A', 'B', 'C', 'D', 'E', 'F#'] },
    { tom: 'G#', escala: ['G#', 'A#', 'C', 'C#', 'D#', 'F', 'G'] },
    { tom: 'A', escala: ['A', 'B', 'C#', 'D', 'E', 'F#', 'G#'] },
    { tom: 'A#', escala: ['A#', 'C', 'D', 'D#', 'F', 'G', 'A'] },
    { tom: 'B', escala: ['B', 'C#', 'D#', 'E', 'F#', 'G#', 'A#'] },
  ]);
  const [optional, setOptional] = React.useState([
    {
      tom: 'C',
      escala: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
    },
    {
      tom: 'C#',
      escala: ['C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C'],
    },
    {
      tom: 'D',
      escala: ['D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#'],
    },
    {
      tom: 'D#',
      escala: ['D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D#'],
    },
    {
      tom: 'E',
      escala: ['E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#'],
    },
    {
      tom: 'F',
      escala: ['F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E'],
    },
    {
      tom: 'F#',
      escala: ['F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F#'],
    },
    {
      tom: 'G',
      escala: ['G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#'],
    },
    {
      tom: 'G#',
      escala: ['G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G#'],
    },
    {
      tom: 'A',
      escala: ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'],
    },
    {
      tom: 'A#',
      escala: ['A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A#'],
    },
    {
      tom: 'B',
      escala: ['B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#'],
    },
  ]);

  const handleReset = () => {
    scrollStatus = false;
    clearInterval(interval);
    interval = null;
    timeScroll = 5;
    setTime(5);
  };

  const handleSelectTom = React.useCallback(
    (e) => {
      const t = tonalidades.filter((x) => x.tom === e.target.value);
      const ot = optional.filter((x) => x.tom === e.target.value);

      setTom(t[0].escala);
      setOptionalTom(ot[0].escala);
      const escala = t[0].escala;
      const editor = document.getElementById('editor');
      const cifras = editor.querySelectorAll('.cifras');
      const cifrasOptions = editor.querySelectorAll('.optionals');

      if (cifras.length > 0) {
        [...cifras].forEach((item) => {
          if (item.innerText.includes('Tom')) return false;
          item.innerText = item.innerText.replace(item.dataset.remove, escala[item.dataset.tom]);
          item.dataset.remove = escala[item.dataset.tom];
        });
      }

      if (cifrasOptions.length > 0) {
        [...cifrasOptions].forEach((item) => {
          if (item.innerText.includes('Tom')) return false;
          const os = { split: '', a: '', b: '', c: '', d: '' };
          const s = item.dataset.tom.split(':');

          os.split = item.innerText.split('/');
          os.a = os.split[0].trim().replace(/[A-Z|#]/g, '');
          os.c = os.split[0].trim();

          if (os.split[1] === undefined) {
            os.b = '';
          } else {
            os.b = os.split[1].trim().replace(/[A-Z|#]/g, '');
            os.d = os.split[1].trim();
          }

          if (s.length === 1) {
            const s1 = ot[0].escala[s[0]];
            item.innerText = item.innerText.replace(os.c + os.d, s1 + os.a);
            item.dataset.remove = s1;
          } else {
            const s1 = ot[0].escala[s[0]];
            const s2 = ot[0].escala[s[1]];

            item.innerText = item.innerText.replace(os.c + '/' + os.d, s1 + os.a + '/' + s2 + os.b);
            // item.dataset.remove = s1 + '/' + s2;
          }
        });
      }
    },
    [tonalidades, optional],
  );

  function handleClose() {
    const searchBar = document.getElementById('search-bar');
    searchBar.classList.remove('open');
  }

  function handleBar(e) {
    e.preventDefault();
    window.scroll(0, 0);
    const editor = document.getElementById('editor');
    const searchBar = document.getElementById('search-bar');
    searchBar.scroll(0, 0);
    searchBar.classList.add('open');
    if (editor) editor.innerHTML = '';
    handleReset();
  }

  function playScroll() {
    scrollStatus = true;
    const end = document.body.scrollHeight - document.body.clientHeight;

    interval = setInterval(() => {
      if (!scrollStatus) clearInterval(interval);
      if (w >= end - 5) {
        clearInterval(interval);
        scrollStatus = false;
      }
      window.scroll(0, w + 50);
      // window.scroll(0, w + timeScroll * 10);
      // if (window.scrollY + 800 >= editor.scrollHeight) clearInterval(interval);
    }, timeScroll * 1000);

    // interval = setInterval(() => {
    //   if (!scrollStatus) clearInterval(interval);
    //   if (w >= end - 20) {
    //     clearInterval(interval);
    //     scrollStatus = false;
    //   }
    //   window.scroll(0, w + 100);
    //   // if (window.scrollY + 800 >= editor.scrollHeight) clearInterval(interval);
    // }, timeScroll * 1000);
  }

  const handleScroll = () => {
    w = window.pageYOffset;
  };

  const pageScroll = React.useCallback(() => {
    scrollStatus = true;
    const refrao = editor.querySelectorAll('.refrao');

    window.addEventListener('scroll', handleScroll, false);
    window.removeEventListener('scroll', pageScrollStop, false);

    refrao[0].classList.add('active');
    window.scroll(0, refrao[0].offsetTop);

    const end = document.body.scrollHeight - document.body.clientHeight;
    interval = setInterval(() => {
      if (!scrollStatus) clearInterval(interval);
      if (w >= end - 5) {
        clearInterval(interval);
        scrollStatus = false;
      }
      window.scroll(0, w + 50);
      // if (window.scrollY + 800 >= editor.scrollHeight) clearInterval(interval);
    }, timeScroll * 1000);
  }, []);

  const subScroll = React.useCallback(() => {
    clearInterval(interval);
    timeScroll = timeScroll - 1;
    setTime(timeScroll);
    if (scrollStatus) playScroll();
  }, []);

  const adScroll = React.useCallback(() => {
    clearInterval(interval);
    timeScroll = timeScroll + 1;
    setTime(timeScroll);
    if (scrollStatus) playScroll();
  }, []);

  const pageScrollStop = () => {
    scrollStatus = false;
    clearInterval(interval);
    interval = null;
  };

  const scrollMemo = React.useMemo(() => {
    return (
      <>
        <div className="page-scroll">
          <button onClick={subScroll}>
            <FiMinusCircle size={18} />
          </button>
          <input type="text" value={time} onChange={(e) => setTime(e.target.value)} />
          <button onClick={adScroll}>
            <FiPlusCircle size={18} />
          </button>
        </div>
        <button className="scroll-start" onClick={pageScroll}>
          <FiPlay size={19} />
        </button>
        <button className="scroll-stop" onClick={pageScrollStop}>
          <FiPause size={19} />
        </button>
      </>
    );
  }, [time, adScroll, subScroll, pageScroll]);

  async function handleSearch(e) {
    e.preventDefault();
    let id = 0;
    const dataSetId = e.target.dataset.id;

    dataSetId === undefined ? (id = search) : (id = dataSetId);

    const storageCifras = JSON.parse(localStorage.getItem('@cifras')) || [];
    const response = storageCifras.filter((item) => +item.harpa === +id);

    // const response = await ajax({
    //   url: `/harpa?page=1&limit=100&harpa=${id}&search=${id}`,
    // });
    // if (response.count <= 0) return toast.error('Hino não encontrado!');
    if (response.length > 0) {
      const content = document.getElementById('hino');
      content.innerHTML = '';
      content.innerHTML += response[0].content;

      const title = document.getElementById('title');
      title.innerHTML = '';
      const h2 = document.createElement('h2');
      const h4 = document.createElement('h4');
      h2.innerText = response[0].titulo;
      if (response[0].harpa) {
        h4.innerText = response[0].subtitulo + ' - ' + response[0].harpa;
      } else {
        h4.innerText = response[0].subtitulo;
      }

      title.appendChild(h2);
      title.appendChild(h4);

      const sr = document.getElementById('select-render');
      sr.innerHTML = '';
      const s = document.createElement('select');
      s.onchange = handleSelectTom;
      s.className = 'select-tom';
      s.innerHTML += `<option value="">Tom ${response[0].tom.toUpperCase()}</option>
      <option value="C">Tom C</option>
      <option value="C#">Tom C#</option>
      <option value="D">Tom D</option>
      <option value="D#">Tom D#</option>
      <option value="E">Tom E</option>
      <option value="F">Tom F</option>
      <option value="F#">Tom F#</option>
      <option value="G">Tom G</option>
      <option value="G#">Tom G#</option>
      <option value="A">Tom A</option>
      <option value="A#">Tom A#</option>
      <option value="B">Tom B</option>`;
      sr.appendChild(s);
      const editor = document.body.querySelector('.editor');
      editor.scroll(0, 0);
      editor.style.border = 'none';
      editor.removeAttribute('contentEditable');
      handleClose();

      // return toast.success('Hino Encontrado!', { position: 'bottom-right' });
      return false;
    }

    return toast.error('Hino não encontrado!', { position: 'bottom-right' });
  }

  React.useEffect(() => {
    async function getStart() {
      const storageCifras = JSON.parse(localStorage.getItem('@cifras')) || [];
      const response = await ajax({ url: `/harpa?page=1&limit=700` });
      if (storageCifras.length > 0) setStartArray(storageCifras);
      if (response.rows && response.rows.length > storageCifras.length) {
        localStorage.setItem('@cifras', JSON.stringify(response.rows));
        setStartArray(response.rows);
      }
    }

    if (startArray.length === 0) getStart();

    () => {
      handleReset();
    };
  }, [startArray]);

  return (
    <Fragment>
      <L.Header className="header-page">
        <L.ToggleWrap className="/" />
        <L.Logo>
          <img src={Logo} title="Logo site" alt="Logo do site" />
        </L.Logo>
        <L.NavBar className="navbar">
          <a href="">
            <FiHome size={20} />
            <span>Home</span>
          </a>
          <a href="/harpa/0">
            <FiEdit2 size={20} />
            <span>Editar</span>
          </a>
          <a href="/editor">
            <FiBook size={20} />
            <span>Editor</span>
          </a>
        </L.NavBar>
      </L.Header>
      <center>
        <L.SearchBar>
          <button onClick={handleBar}>
            <FiSearch size={25} />
          </button>
          <button onClick={subScroll}>
            <FiMinusCircle size={25} />
          </button>
          <button onClick={adScroll}>
            <FiPlusCircle size={25} />
          </button>
        </L.SearchBar>
        <L.DisplayBar className="open" id="search-bar">
          <div className="close" onClick={handleClose}>
            <FiX size={20} />
          </div>
          <L.Row>
            <E.Input col={5} type="text" name="search" inputMode="numeric" placeholder="Nª do hino.." onChange={(e) => setSearch(e.target.value)} />
            <E.Button onClick={handleSearch}>
              <FiArrowRight size={20} />
            </E.Button>
            <E.Checkbox>
              <input type="checkbox" name="harpa" checked={harpa} onChange={() => setHarpa(!harpa)} />
              <span style={{ color: '#fff' }}>Harpa?</span>
            </E.Checkbox>
          </L.Row>
          <L.RowLink>
            {startArray.length > 0 &&
              startArray.map((item) => (
                <L.LinkHarpa key={item.id} href="#" Width={2.3} data-id={item.id} onClick={handleSearch}>
                  {item.id}
                </L.LinkHarpa>
              ))}
          </L.RowLink>
        </L.DisplayBar>
      </center>
      <L.Center id="content">
        <L.Bar>
          <E.Row className="title" wrap="wrap" id="title" />
        </L.Bar>
        <L.Bar style={{ display: 'flex', alignItems: 'center' }}>
          <E.Row bottom={0.4} id="select-render" />
          <E.Row bottom={0.4}>{scrollMemo}</E.Row>
        </L.Bar>
      </L.Center>
      <center id="hino" style={{ height: "calc('100% - 9rem')" }} />
    </Fragment>
  );
}

// onKeyDown={getCopy}
