/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';
import * as C from '../../components/wrapper';
import pTypes from 'prop-types';

export default function Harpa() {
  const [cifra, setCifra] = React.useState('');
  const [frase, setFrase] = React.useState('');
  const [pad, setPad] = React.useState(0);
  const [tom, setTom] = React.useState([]);
  const [tonalidades, setTonalidades] = React.useState([
    { tom: 'C', escala: ['C', 'Dm', 'Em', 'F', 'G', 'Am', 'B'] },
    { tom: 'D', escala: ['D', 'Em', 'F#m', 'G', 'A', 'Bm', 'C#'] },
    { tom: 'E', escala: ['E', 'F#m', 'G#m', 'A', 'B', 'C#m', 'D#'] },
    { tom: 'F', escala: ['F', 'Gm', 'Am', 'Bb', 'C', 'Dm', 'E'] },
    { tom: 'G', escala: ['G', 'Am', 'Bm', 'C', 'D', 'Em', 'F#'] },
    { tom: 'A', escala: ['A', 'Bm', 'C#m', 'D', 'E', 'F#m', 'G#'] },
    { tom: 'B', escala: ['B', 'C#m', 'D#m', 'E', 'F#', 'G#m', 'A#'] },
  ]);

  const [extraColumns, setExtraColumns] = React.useState([]);

  const handleSelectTom = React.useCallback(
    (e) => {
      const t = tonalidades.filter((x) => x.tom === e.target.value);
      setTom(t[0].escala);
    },
    [tonalidades],
  );

  function updateFrase(e) {
    const value = e.target.value;
    const offsets = extraColumns.filter((t) => t.offsets);
    setExtraColumns([
      {
        offsets: offsets.length < 0 || [{ cifra: 0, pad: 0, rest: '' }],
        text: value,
      },
    ]);
    console.log(value);
  }

  function Update() {
    const content = document.getElementById('content');
    console.log(content);
    console.log(cifra);
    console.log(pad);
    console.log(frase);
    console.log(tom);
    const offsets = [{ cifra: 0, pad: 0, rest: '' }];
    setExtraColumns([
      {
        offsets: offsets,
        text: 'Minha vida seja sim    consagrada a Ti, Senhor',
      },
    ]);
  }

  React.useEffect(() => {
    const t = tonalidades.filter((x) => x.tom === 'C');
    setTom(t[0].escala);
  }, [tonalidades]);

  return (
    <>
      <C.HeaderCreate>
        <input type="text" placeholder="Titulo..." defaultValue="Consagrado Ao Senhor" />
        <input type="text" placeholder="Subtitulo..." defaultValue="430 Harpa Cristã" />
        <select onChange={handleSelectTom}>
          <option value="C">Tom C</option>
          <option value="D">Tom D</option>
          <option value="E">Tom E</option>
          <option value="F">Tom F</option>
          <option value="G">Tom G</option>
          <option value="A">Tom A</option>
          <option value="B">Tom B</option>
        </select>

        <div style={{ display: 'flex', padding: '5px 0' }}>
          {tom.length > 0 &&
            tom.map((t) => (
              <p
                key={Math.random()}
                style={{
                  textAlign: 'center',
                  padding: '4px',
                  color: '#999',
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  margin: '2px',
                  width: '50px',
                  height: '40',
                  borderRadius: '2px',
                }}
              >
                {t}
              </p>
            ))}
        </div>

        {/* <input type="text" placeholder="Cifra.." onChange={(e) => setCifra(e.target.value)} /> */}
        <input type="number" inputMode="numeric" defaultValue={0} onChange={(e) => setPad(e.target.value)} />
        <div style={{ display: 'flex', padding: '5px 0' }}>
          <input type="text" placeholder="Frase.." onChange={updateFrase} />
          <button onClick={Update}>Update</button>
        </div>
      </C.HeaderCreate>
      <C.WrapperTopCenterCreate>
        <C.Line id="content">
          {extraColumns &&
            extraColumns.map((item) => (
              <C.Row key={Math.random(0, 1000)} wrap="wrap">
                {item.ref && <h4 style={{ marginTop: 20, fontWeight: 500 }}>Refrão</h4>}
                <C.Col col={24}>
                  {item.offsets &&
                    item.offsets.map((offset) => (
                      <Fragment key={Math.random(0, 1000)}>
                        <C.Offset text={`${tom[offset.cifra] + offset.rest}`} pad={offset.pad} />
                      </Fragment>
                    ))}
                </C.Col>
                <C.CifraText col={24}>{item.text}</C.CifraText>
              </C.Row>
            ))}
        </C.Line>
      </C.WrapperTopCenterCreate>
    </>
  );
}
