/* eslint-disable react/prop-types */
//** Navigate import Loader from '../components/Loading'; */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
// import AuthContext from '../contexts/auth';

export default function RouteWrapper({ component: Component, isPrivate, role, ...rest }) {
  // const { signed } = useContext(AuthContext);

  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  function isTrue(match) {
    const roleUser = localStorage.getItem('user@roles') || false;
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(roleUser);
  }

  function can(text) {
    if (isTrue('superadmin') || isTrue('admin')) return true;
    return isTrue(text);
  }

  // if (signed && !isPrivate) return <Redirect to="/" />;

  if (role && !can(role)) return <Redirect to="/error/401" />;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
