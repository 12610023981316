import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,200;1,400;1,700;1,900&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
  *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: inherit;
  }

  *:focus {
    outline: 0;
  }

  html {
    scrollbar-width: thin;
    font-size: 100%;
    scroll-behavior: smooth;

    /* @media only screen and (max-width: 50em) {
      font-size: 100%;
      line-height: 1;
    } */
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    background-color: #EFEFEF;
    color: #333;
    font-family: ${({ theme }) => theme.font.family.default};
    text-rendering: optimizeLegibility;
    // font-family: "Montserrat", sans-serif;
  }

  html,body, #root, #__next {
    width: 100%;
    height: 100%;
  }

  #root, #__next {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  img{
    display: block;
    width: 100%;
  }

  ul,
  li,
  input,
  div,
  a,
  span,
  small,
  button,
  svg {
      -webkit-tap-highlight-color: rgba(#000, 0);
  }

  a {
    text-decoration: none;
  }

  ul {
      list-style: none;
  }

  button{
      cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: inherit !important;
      -webkit-box-shadow: none;
      transition: background-color 5000s ease-in-out 0s;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: #989898;
  }

  ::-webkit-scrollbar-track {
      //-webkit-box-shadow: inset 0 0 6px rgb(24, 24, 107);
      border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      // -webkit-box-shadow: inset 0 0 6px #545454;
      background-color: #5b63ff; //#06092b #5b63ff;
  }

  body, #root {
    background-color: #fff;
  }

  #root {
    // display: flex;
    // flex-direction: column;
    height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  p {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .cifras, .optionals {
    font-family: 'Roboto',sans-serif;
    width: calc(100% / 12 * );
    font-weight: 400;
    color: #40f;
    /* text-shadow: 1px 1px 1px #09001e; */
    padding-left: calc(100% / 24 * 0);
    font-size: x-large; //1.1rem 14pt
    line-height: 1;
    @media only screen and (max-width: 50em) {
      font-size: 4.8vw;
      line-height: 0.2;
    }
  }

  .frases {
    font-size: x-large;
    margin-bottom: 0.1rem;
    @media only screen and (max-width: 50em) {
      font-size: 4.8vw;
    }
  }
  .editor{
    color: #000;
    /* border: 1px solid #444; */
    width: 800px;
    height: calc(${window.innerHeight + 'px'} - 9.5rem);
    text-align: left;
    padding: 1rem 5px;
    border: none;
    /* overflow: auto; */

    @media only screen and (max-width: 50em) {
      width: 100%; //414
      height: 100%;
    }
  }
  .select-tom {
    height: 1.7rem;
    border: none;
    border-radius: 3px;
    margin: 0 2px;
    padding: 0 1rem;
    background-color: #fff;
    @media only screen and (max-width: 50em) {
      font-size: 1.2rem;
    }
  }
  .refrao {
    font-weight: 500;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    background-color: #dfdfdf4d;

    &.active {
      background-color: #dfdfdf; //#dfdfdf #00ff87
    }
  }
  .title {
    color: #fff;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 4px;
    /* margin-bottom: 1rem; */
    & h2, h4 {
      font-weight: 500;
      margin: 2px 0
    }
  }
  .page-scroll {
    display: flex;
    align-items: center;
    margin: 0 5px;

    & button {
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.7rem;
      text-align: center;
      line-height: 0.2;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & input {
      width: 1.7rem;
      text-align: center;
      border: none;
      font-size: 1.15rem;
      height: 1.5rem;
      border-radius: 2px;
      margin: 0 2px;
    }
  }
  .scroll-start,  .scroll-stop{
    border: none;
    font-size: 1rem;
    text-align: center;
    border-radius: 2px;
    padding: 3px 1rem;

    display: flex;
    align-items: center;
    margin: 0 2px;
    transition: all 0.2s ease;

    &:active {
      transform: scale(1.3);
    }
  }
`;

export default GlobalStyles;
