import { BrowserRouter } from 'react-router-dom';

//** Toast alert
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//**Rotas */
import Routes from './routes';

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <ToastContainer autoClose={3000} />
    </BrowserRouter>
  );
}

export default App;
