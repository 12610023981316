/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';
import * as C from '../../components/wrapper';
import pTypes from 'prop-types';

export default function Harpa() {
  const [tom, setTom] = React.useState([]);
  const [tonalidades, setTonalidades] = React.useState([
    { tom: 'C', escala: ['C', 'Dm', 'Em', 'F', 'G', 'Am', 'B'] },
    { tom: 'D', escala: ['D', 'Em', 'F#m', 'G', 'A', 'Bm', 'C#'] },
    { tom: 'E', escala: ['E', 'F#m', 'G#m', 'A', 'B', 'C#m', 'D#'] },
    { tom: 'F', escala: ['F', 'Gm', 'Am', 'Bb', 'C', 'Dm', 'E'] },
    { tom: 'G', escala: ['G', 'Am', 'Bm', 'C', 'D', 'Em', 'F#'] },
    { tom: 'A', escala: ['A', 'Bm', 'C#m', 'D', 'E', 'F#m', 'G#'] },
    { tom: 'B', escala: ['B', 'C#m', 'D#m', 'E', 'F#', 'G#m', 'A#'] },
  ]);

  const [extraColumns, setExtraColumns] = React.useState([
    {
      offsets: [
        { cifra: 0, pad: 0, rest: '' },
        { cifra: 5, pad: 7, rest: '' },
        { cifra: 4, pad: 1, rest: '' },
        { cifra: 0, pad: 7, rest: '' },
        { cifra: 4, pad: 2, rest: '' },
      ],
      text: 'Minha vida seja sim    consagrada a Ti, Senhor',
    },
    {
      offsets: [
        { cifra: 0, pad: 0, rest: '' },
        { cifra: 0, pad: 4, rest: '7' },
        { cifra: 3, pad: 4, rest: '' },
        { cifra: 1, pad: 0, rest: '' },
        { cifra: 4, pad: 1, rest: '' },
        { cifra: 0, pad: 5, rest: '' },
        { cifra: 4, pad: 1, rest: '' },
      ],
      text: 'Possas sempre Tu por mim    operar o Teu amor',
    },
    {
      ref: true,
      offsets: [
        { cifra: 3, pad: 0, rest: '' },
        { cifra: 0, pad: 10, rest: '' },
      ],
      text: 'A minh`alma lava salvador',
    },
    {
      offsets: [
        { cifra: 3, pad: 0, rest: '' },
        { cifra: 1, pad: 7, rest: '' },
        { cifra: 0, pad: 3, rest: '' },
        { cifra: 4, pad: 2, rest: '' },
      ],
      text: 'No Teu sangue puro, carmezim',
    },
    {
      offsets: [
        { cifra: 0, pad: 0, rest: '' },
        { cifra: 3, pad: 8, rest: '' },
        { cifra: 1, pad: 4, rest: '' },
      ],
      text: 'Minha vida toma   para ser, Senhor',
    },
    {
      offsets: [
        { cifra: 0, pad: 0, rest: '' },
        { cifra: 4, pad: 2, rest: '' },
        { cifra: 0, pad: 6, rest: '' },
        { cifra: 4, pad: 1, rest: '' },
        { cifra: 0, pad: 1, rest: '' },
      ],
      text: 'Tua  para sempre, sim',
    },
  ]);

  const handleAddExtraColumn = React.useCallback(
    (e) => {
      const t = tonalidades.filter((x) => x.tom === e.target.value);
      setTom(t[0].escala);
    },
    [tonalidades],
  );

  React.useEffect(() => {
    const t = tonalidades.filter((x) => x.tom === 'A');
    setTom(t[0].escala);
  }, [tonalidades]);

  return (
    <>
      <C.Header>
        <h3>Consagrado Ao Senhor</h3>
        <h6 style={{ marginBottom: '8px' }}>430 Harpa Cristã </h6>
        <select onChange={handleAddExtraColumn}>
          <option value="C">Tom C</option>
          <option value="D">Tom D</option>
          <option value="E">Tom E</option>
          <option value="F">Tom F</option>
          <option value="G">Tom G</option>
          <option value="A">Tom A</option>
          <option value="B">Tom B</option>
        </select>
      </C.Header>
      <C.WrapperTopCenter>
        <C.Line>
          {extraColumns &&
            extraColumns.map((item) => (
              <C.Row key={Math.random(0, 1000)} wrap="wrap">
                {item.ref && <h4 style={{ marginTop: 20, fontWeight: 500 }}>Refrão</h4>}
                <C.Col col={24}>
                  {item.offsets &&
                    item.offsets.map((offset) => (
                      <Fragment key={Math.random(0, 1000)}>
                        <C.Offset text={`${tom[offset.cifra] + offset.rest}`} pad={offset.pad} />
                      </Fragment>
                    ))}
                </C.Col>
                <C.CifraText col={24}>{item.text}</C.CifraText>
              </C.Row>
            ))}
        </C.Line>
      </C.WrapperTopCenter>
    </>
  );
}
