export const theme = {
  colors: {
    primary: '#06092b', // #0A1128 #06092b
    secondary: '#dc143c',
    white: '#FFFFFF',
  },
  font: {
    family: {
      default: "'Montserrat', sans-serif",
      secondary: "'Open Sans', sans-serif",
    },
    sizes: {
      small: '8rem',
      strong: '1.6rem',
      medium: '2.4rem',
      largeLow: '3.2rem',
      largeMedium: '4.0rem',
      large: '4.8rem',
      hugeMedium: '5.6rem',
      huge: '6.4rem',
    },
  },
  media: {
    lteMedium: '(max-width: 768px)',
  },
  spacings: {
    small: '8rem',
    strong: '1.6rem',
    medium: '2.4rem',
    largeLow: '3.2rem',
    largeMedium: '4.0rem',
    large: '4.8rem',
    hugeMedium: '5.6rem',
    huge: '6.4rem',
  },
};
