/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';
import pTypes from 'prop-types';
import * as C from '../../components/wrapper';
import * as E from './style';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { ajax, serialize } from '../../api/api';
import { FiSave, FiCornerLeftDown, FiCornerUpRight, FiCode, FiTrash2 } from 'react-icons/fi';

export default function Edit() {
  const { id } = useParams();
  const [disabled, setDisabled] = React.useState(false);
  const [disabledOp, setDisabledOp] = React.useState(true);
  const [titulo, setTitulo] = React.useState('');
  const [subtitulo, setSubtitulo] = React.useState('');
  const [harpa, setHarpa] = React.useState('');
  const [saveTom, setSaveTom] = React.useState(null);
  const [tom, setTom] = React.useState([]);
  const [optionalTom, setOptionalTom] = React.useState([]);
  const [cifra, setCifra] = React.useState(undefined);
  const [frase, setFrase] = React.useState(undefined);
  const [tonalidades, setTonalidades] = React.useState([
    { tom: 'C', escala: ['C', 'D', 'E', 'F', 'G', 'A', 'B'] },
    { tom: 'C#', escala: ['C#', 'D#', 'F', 'F#', 'G#', 'A#', 'C'] },
    { tom: 'D', escala: ['D', 'E', 'F#', 'G', 'A', 'B', 'C#'] },
    { tom: 'D#', escala: ['D#', 'Fm', 'Gm', 'G#', 'A#', 'C', 'D'] },
    { tom: 'E', escala: ['E', 'F#', 'G#', 'A', 'B', 'C#', 'D#'] },
    { tom: 'F', escala: ['F', 'G', 'A', 'Bb', 'C', 'D', 'E'] },
    { tom: 'F#', escala: ['F#', 'G#', 'A#', 'B', 'C#', 'D#', 'F'] },
    { tom: 'G', escala: ['G', 'A', 'B', 'C', 'D', 'E', 'F#'] },
    { tom: 'G#', escala: ['G#', 'A#', 'C', 'C#', 'D#', 'F', 'G'] },
    { tom: 'A', escala: ['A', 'B', 'C#', 'D', 'E', 'F#', 'G#'] },
    { tom: 'A#', escala: ['A#', 'C', 'D', 'D#', 'F', 'G', 'A'] },
    { tom: 'B', escala: ['B', 'C#', 'D#', 'E', 'F#', 'G#', 'A#'] },
  ]);
  const [optional, setOptional] = React.useState([
    { tom: 'C', escala: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'] },
    { tom: 'C#', escala: ['C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C'] },
    { tom: 'D', escala: ['D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#'] },
    { tom: 'D#', escala: ['D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D#'] },
    { tom: 'E', escala: ['E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#'] },
    { tom: 'F', escala: ['F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E'] },
    { tom: 'F#', escala: ['F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F#'] },
    { tom: 'G', escala: ['G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#'] },
    { tom: 'G#', escala: ['G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G#'] },
    { tom: 'A', escala: ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'] },
    { tom: 'A#', escala: ['A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A#'] },
    { tom: 'B', escala: ['B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#'] },
  ]);

  //**EVENTO DO SELECT */
  const handleSelectTom = React.useCallback(
    (e) => {
      const t = tonalidades.filter((x) => x.tom === e.target.value);
      const ot = optional.filter((x) => x.tom === e.target.value);

      setSaveTom(t[0].tom);
      setTom(t[0].escala);
      setOptionalTom(ot[0].escala);

      const escala = t[0].escala;
      const editor = document.getElementById('editor');
      const cifras = editor.querySelectorAll('.cifras');
      const cifrasOptions = editor.querySelectorAll('.optionals');

      if (cifras.length > 0) {
        [...cifras].forEach((item) => {
          if (item.innerText.includes('Tom')) return false;
          item.innerText = item.innerText.replace(item.dataset.remove, escala[item.dataset.tom]);
          item.dataset.remove = escala[item.dataset.tom];
          // item.innerText = item.innerText.replace(item.innerText.trim(), escala[item.dataset.tom]);
        });
      }

      if (cifrasOptions.length > 0) {
        [...cifrasOptions].forEach((item) => {
          if (item.innerText.includes('Tom')) return false;
          const os = { split: '', a: '', b: '', c: '', d: '' };
          const s = item.dataset.tom.split(':');

          os.split = item.innerText.split('/');
          os.a = os.split[0].trim().replace(/[A-Z|#]/g, '');
          os.c = os.split[0].trim();

          if (os.split[1] === undefined) {
            os.b = '';
          } else {
            os.b = os.split[1].trim().replace(/[A-Z|#]/g, '');
            os.d = os.split[1].trim();
          }

          if (s.length === 1) {
            const s1 = ot[0].escala[s[0]];
            item.innerText = item.innerText.replace(os.c + os.d, s1 + os.a);
            item.dataset.remove = s1;
          } else {
            const s1 = ot[0].escala[s[0]];
            const s2 = ot[0].escala[s[1]];

            item.innerText = item.innerText.replace(os.c + '/' + os.d, s1 + os.a + '/' + s2 + os.b);
          }
        });
      }
    },
    [tonalidades, optional],
  );

  //**EVENTO DO CLICK DAS CIFRAS */
  function myFunction(e) {
    setCifra(e.target);
    if (e.target.classList.contains('optionals')) {
      setDisabledOp(false);
    } else {
      setDisabledOp(true);
    }
  }

  function fraseSelect(e) {
    setFrase(e.target);
  }

  function addRefrao() {
    const editor = document.getElementById('editor');
    const r = document.createElement('h3');
    r.innerText = 'Refrao';
    r.className = 'refrao';
    r.setAttribute('autocorrect', 'off');
    r.setAttribute('spellcheck', false);
    editor.appendChild(r);
    document.querySelectorAll('.editor br').forEach((item) => item.remove());
  }
  //**EVENTO ADICIONAR LINHAS DE CIFRAS E FRASE */
  function addFrase() {
    setDisabled(true);
    const editor = document.getElementById('editor');
    const b = document.createElement('b');
    b.dataset.tom = '';
    b.dataset.remove = '';
    b.className = 'cifras';
    b.setAttribute('autocorrect', 'off');
    b.setAttribute('spellcheck', false);
    b.onclick = myFunction;
    b.innerText = 'Tom  ';

    const f = document.createElement('p');
    f.className = 'frases';
    f.setAttribute('autocorrect', 'off');
    f.setAttribute('spellcheck', false);
    f.onchange = fraseSelect;
    f.innerText = '...';
    setFrase(f);

    editor.appendChild(b);
    editor.appendChild(f);
    setTimeout(() => {
      setDisabled(false);
    }, 300);
  }

  function addOption() {
    if (cifra === undefined) return false;
    setDisabled(true);

    const editor = document.getElementById('editor');
    const b = document.createElement('b');
    b.className = 'optionals';
    b.setAttribute('autocorrect', 'off');
    b.setAttribute('spellcheck', false);
    b.dataset.tom = '';
    b.onclick = myFunction;
    b.innerText = ' Op ';
    // cifra.parentNode.insertBefore(b, cifra);
    cifra.insertAdjacentElement('afterend', b);
    setTimeout(() => {
      setDisabled(false);
    }, 300);
  }

  //**EVENTO ADICIONAR TOM */
  function addTom() {
    setDisabled(true);
    if (cifra === undefined) return false;
    const editor = document.getElementById('editor');
    const b = document.createElement('b');
    b.className = 'cifras';
    b.setAttribute('autocorrect', 'off');
    b.setAttribute('spellcheck', false);
    b.dataset.tom = '';
    b.onclick = myFunction;
    b.innerText = 'Tom ';
    // cifra.parentNode.insertBefore(b, cifra);
    cifra.insertAdjacentElement('afterend', b);
    setCifra(b);
    setTimeout(() => {
      setDisabled(false);
    }, 300);
  }

  //**EVENTO ADICIONA TOM A CIFRA*/
  function ediOptionaltTom(e) {
    setDisabled(true);
    if (cifra.innerText.includes('Op')) {
      cifra.innerText = e.target.innerText;
      cifra.dataset.tom = e.target.dataset.tom;
    } else if (cifra.innerText.includes('/')) {
      const s = cifra.dataset.tom.split(':');
      cifra.dataset.tom = s[0] + ':' + e.target.dataset.tom;
      cifra.innerText = cifra.innerText + e.target.innerText;
      cifra.dataset.remove = cifra.innerText;
    } else {
      cifra.innerText = cifra.innerText + '/' + e.target.innerText;
      cifra.dataset.tom = cifra.dataset.tom + ':' + e.target.dataset.tom;
      cifra.dataset.remove = cifra.innerText;
    }
    setTimeout(() => {
      setDisabled(false);
    }, 300);
  }

  //**EVENTO ADICIONA TOM A CIFRA*/
  function editTom(e) {
    setDisabled(true);
    cifra.innerText = cifra.innerText.replace(cifra.innerText.trim(), e.target.innerText);
    cifra.dataset.tom = e.target.dataset.tom;
    cifra.dataset.remove = e.target.innerText;
    setTimeout(() => {
      setDisabled(false);
    }, 300);
  }

  //**EVENTO CTRL + V CAPTURAR TEXTO */
  //**EVENTO CTRL + V CAPTURAR TEXTO */
  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text');
    if (frase !== undefined) {
      if (frase.innerText.includes('...')) frase.innerText = '';
      frase.innerText += text + ' ';
    }
  };

  //**EVENTO DE ENVIO DOS DADOS PARA O MYSQL */
  async function handleSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    const editor = document.getElementById('editor');
    const content = document.getElementById('content');
    const params = [
      { name: 'titulo', value: titulo },
      { name: 'subtitulo', value: subtitulo },
      { name: 'harpa', value: harpa },
      { name: 'tom', value: saveTom },
      { name: 'content', value: editor.outerHTML },
    ];

    const body = await serialize(params);

    if (id) {
      const response = await ajax({ url: '/harpa/' + id, method: 'put', body, json: true });
      if (response.success) {
        setTimeout(() => {
          setDisabled(false);
        }, 1000);
        editor.innerHTML = '';
        return toast.success('Atualizado com sucesso!');
      }
    } else {
      const response = await ajax({ url: '/harpa/store', method: 'post', body, json: true });
      if (response.success) {
        setTimeout(() => {
          setDisabled(false);
        }, 1000);
        editor.innerHTML = '';
        return toast.success('Hino registrado com sucesso!');
      }
    }

    setDisabled(false);
    return toast.error('Ops! aconteceu um erro!');
    // editor.remove();
    // content.innerHTML += response.data.content;
    //
  }

  //EVENTO REMOVE CIFRA
  function handleRemove(e) {
    e.preventDefault();
    cifra.remove();
  }

  //LOAD HINO EDIT
  const handleGetHino = React.useCallback(
    async (s) => {
      const response = await ajax({ url: `/harpa/` + s });
      if (response.success) {
        setTitulo(response.data.titulo);
        setSubtitulo(response.data.subtitulo);
        setHarpa(response.data.harpa);

        const content = document.getElementById('editor');
        const html = response.data.content.replace(/<pre[^>]*>?/gm, '');
        content.innerHTML = html;

        const allCifras = content.querySelectorAll('.cifras');
        [...allCifras].forEach((item) => {
          item.addEventListener('click', myFunction, false);
        });

        const allOptionals = content.querySelectorAll('.optionals');
        [...allOptionals].forEach((item) => {
          item.addEventListener('click', myFunction, false);
        });

        const tn = response.data.tom.toUpperCase();

        const t = tonalidades.filter((x) => x.tom === tn);
        const ot = optional.filter((x) => x.tom === tn);
        setTom(t[0].escala);
        setSaveTom(t[0].tom);
        setOptionalTom(ot[0].escala);

        const select = document.getElementById('tom-select');
        [...select.children].forEach((op) => {
          if (op.value === tn) op.setAttribute('selected', 'true');
        });
      }
    },
    [tonalidades, optional],
  );

  // React.useEffect(() => {
  //   const editor = document.getElementById('editor');
  //   const handlePasteAnywhere = (event) => {
  //     console.log(event.clipboardData.getData('text'));
  //   };

  //   editor.addEventListener('paste', handlePasteAnywhere);

  //   return () => {
  //     editor.removeEventListener('paste', handlePasteAnywhere);
  //   };
  // }, []);

  React.useEffect(() => {
    if (id) handleGetHino(id);
  }, [tonalidades, id, handleGetHino]);

  return (
    <Fragment>
      <center id="content">
        <E.DisplayBar>
          <E.Row bottom={0.4}>
            <E.Input col={5} type="text" name="titulo" placeholder="Titulo.." defaultValue={titulo} onChange={(e) => setTitulo(e.target.value)} />
            <E.Input col={3} type="text" name="subtitulo" placeholder="Subtitulo.." defaultValue={subtitulo} onChange={(e) => setSubtitulo(e.target.value)} />
            <E.Input
              col={2}
              type="text"
              name="harpa"
              placeholder="Harpa.."
              defaultValue={harpa}
              inputMode="numeric"
              onChange={(e) => setHarpa(e.target.value)}
            />
          </E.Row>
          <E.Row>
            <E.Select name="tom" id="tom-select" onChange={handleSelectTom}>
              <option value="">Tom</option>
              <option value="C">Tom C</option>
              <option value="C#">Tom C#</option>
              <option value="D">Tom D</option>
              <option value="D#">Tom D#</option>
              <option value="E">Tom E</option>
              <option value="F">Tom F</option>
              <option value="F#">Tom F#</option>
              <option value="G">Tom G</option>
              <option value="G#">Tom G#</option>
              <option value="A">Tom A</option>
              <option value="A#">Tom A#</option>
              <option value="B">Tom B</option>
            </E.Select>
            <E.Button onClick={addFrase} disabled={disabled}>
              <FiCornerLeftDown size={20} />
            </E.Button>
            <E.Button onClick={addTom} disabled={disabled}>
              <FiCornerUpRight size={20} />
            </E.Button>
            <E.Button onClick={addOption} disabled={disabled}>
              Op
            </E.Button>
            <E.Button onClick={addRefrao} disabled={disabled}>
              <FiCode size={20} />
            </E.Button>
            <E.Button onClick={handleRemove} disabled={disabled}>
              <FiTrash2 size={20} />
            </E.Button>
            <E.Button onClick={handleSubmit} disabled={disabled}>
              <FiSave size={20} />
            </E.Button>
          </E.Row>
          <E.Row>
            <div style={{ display: 'flex', padding: '5px 0' }}>
              {tom.length > 0 &&
                tom.map((t, i) => (
                  <E.RowTom key={Math.random()} data-tom={i} onClick={editTom}>
                    {t}
                  </E.RowTom>
                ))}
            </div>
          </E.Row>
          <E.Row>
            <div style={{ display: 'flex', padding: '5px 0', flexWrap: 'wrap' }}>
              {optionalTom.length > 0 &&
                optionalTom.map((t, i) => (
                  <E.RowTom className={!disabledOp ? '' : 'disabled'} key={Math.random()} data-tom={i} onClick={ediOptionaltTom}>
                    {t}
                  </E.RowTom>
                ))}
            </div>
          </E.Row>
        </E.DisplayBar>
        <E.Editor className="editor" id="editor" onPaste={handlePaste} contentEditable={true}></E.Editor>
      </center>
    </Fragment>
  );
}

// onKeyDown={getCopy}
