/* eslint-disable react/prop-types */
import styled from 'styled-components';

export const Wrapper = styled.main`
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: #06092b;
  color: #fff;
  width: 100%;
  height: 100%;
  /* padding: 3rem; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //@media only screen and (max-width: 50em) {
  //display: block;
  //}
`;

export const Header = styled.main`
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: #06092bd6; //06092b
  color: #fff;
  width: 100%;
  height: 6rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;

  //@media only screen and (max-width: 50em) {
  //display: block;
  //}
`;

export const HeaderCreate = styled.main`
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: #06092bd6; //06092b
  color: #fff;
  width: 100%;
  height: 11rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;

  //@media only screen and (max-width: 50em) {
  //display: block;
  //}
`;

export const WrapperTopCenterCreate = styled.main`
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: #06092b;
  color: #fff;
  width: 100%;
  height: calc(100% - 11rem);
  /* padding: 3rem; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  //@media only screen and (max-width: 50em) {
  //display: block;
  //}
`;

export const WrapperTopCenter = styled.main`
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: #06092b;
  color: #fff;
  width: 100%;
  height: calc(100% - 6rem);
  /* padding: 3rem; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  //@media only screen and (max-width: 50em) {
  //display: block;
  //}
`;

export const Line = styled.div`
  background-color: #06092b;
  color: #fff;
  width: 414px;
  height: calc(100% - 3rem);
  padding: 1rem 0 0 1rem;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* align-items: start;
  justify-content: start; */
`;
export const Flex = styled.div`
  flex: 1;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-wrap: ${(props) => props['wrap']};
  margin-bottom: 8px;
`;

export const NoWrap = styled.div`
  width: 100%;
  display: flex;
`;

// export const Col = styled.div`
//   flex: 1;
// `;

export const Col = styled.div`
  /* font-size: calc(2px + 2.3vmin); // 32px */
  background-color: ${(props) => props['BG']};
  width: calc(100% / 24 * ${(props) => props['col']});
`;

export const CifraText = styled.pre`
  /* font-size: calc(2px + 2.3vmin); // 32px */
  font-size: 1rem;
  background-color: ${(props) => props['BG']};
  width: calc(100% / 12 * ${(props) => props['col']});
`;

function TextRender({ children, className, text, ...rest }) {
  return (
    <p className={className} {...rest}>
      {children}
      {text}
    </p>
  );
}

export const Text = styled(TextRender)`
  font-size: calc(5px + 2.3vmin); // 32px
  width: calc(100% / 12 * ${(props) => props['col']});
  background-color: ${(props) => props['BG']};
`;

function CifraRender({ children, className, text, ...rest }) {
  return (
    <span className={className} {...rest}>
      {children} {text}
    </span>
  );
}

export const Cifra = styled(CifraRender)`
  font-size: calc(1px + 2.3vmin); // 32px
  background-color: ${(props) => props['BG']};
  color: ${(props) => props['color']};
  width: calc(100% / 12 * ${(props) => props['col']});
  font-weight: 600;
  color: #ffd700;
  padding-left: calc(100% / 12 * ${(props) => props['offset']});
  margin: 5px;
`;

function OffsetColumn({ children, className, text, ...rest }) {
  return (
    <span className={className} {...rest}>
      {children} {text}
    </span>
  );
}

export const Offset = styled(OffsetColumn)`
  /* font-size: calc(2px + 2.3vmin); // 32px */
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  background-color: ${(props) => props['BG']};
  color: ${(props) => props['color']};
  width: calc(100% / 12 * ${(props) => props['col']});
  font-weight: 400;
  color: #ffd700;
  /* padding-left: ${(props) => props['pad'] + 'px'}; */
  padding-left: calc(100% / 24 * ${(props) => props['pad']});
`;
