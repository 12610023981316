import React from 'react';
import { BrowserRouter as Switch, Link } from 'react-router-dom';
import Route from './route';

import Home from '../views/home';
import Harpa from '../views/harpa';
import HarpaCreate from '../views/harpa/create';
import Editor from '../views/editor';
import List from '../views/editor/list';
import Edit from '../views/editor/edit';

export default function BasicExample() {
  return (
    <Switch>
      <Route exact path="/" component={List} />
      <Route exact path="/harpa" component={Harpa} />
      <Route exact path="/harpa/:id" component={Edit} />
      <Route exact path="/registrar" component={HarpaCreate} />
      <Route exact path="/editor" component={Editor} />
      <Route exact path="/list" component={List} />
      <Route exact path="/about" component={About} />
      <Route exact path="/dashboard" component={Dashboard} />
    </Switch>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}
