/* eslint-disable react/prop-types */
import styled from 'styled-components';

export const DisplayBar = styled.div`
  background-color: #06092bd6;
  border: 1px solid;
  width: 800px; //414
  height: 7rem;
  text-align: left;
  padding: 5px;

  @media only screen and (max-width: 50em) {
    width: 100%; //414
    height: auto;
  }
`;

function PreEditor({ children, className, ...rest }) {
  return (
    <pre className={className} {...rest}>
      {children}
    </pre>
  );
}

export const Editor = styled(PreEditor)`
  background-color: #06092b;
  color: #fff;
  border: 1px solid;
  width: 800px;
  height: calc(${window.innerHeight + 'px'} - 9.5rem);
  text-align: left;
  padding: 5px;

  @media only screen and (max-width: 50em) {
    width: 100%; //414
    height: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props['bottom'] + 'rem'};
`;

export const Select = styled.select`
  height: 1.7rem;
  border: none;
  border-radius: 3px;
  margin: 0 2px;
  padding: 0 1rem;
`;

export const RowTom = styled.p`
  text-align: center;
  padding: 4px;
  color: #000;
  cursor: pointer;
  background-color: #e9e9ed;
  border-radius: 2px;
  margin: 2px;
  width: 50px;
  height: 1.75rem;
  font-size: 1rem;
`;

export const Button = styled.button`
  text-align: center;
  padding: 4px 1rem;
  color: #000;
  cursor: pointer;
  background-color: #e9e9ed;
  border-radius: 2px;
  margin: 2px;
  border: none;
  height: 1.75rem;
  font-size: 1rem;
  @media only screen and (max-width: 50em) {
    line-height: 0.5;
  }
`;

export const Input = styled.input`
  width: calc(100% / 12 * ${(props) => props['col']});
  padding: 0 4px;
  height: 1.75rem;
  margin: 0 2px;
  border: none;
  border-radius: 3px;
`;

export const Table = styled.table``;

// text-align: 'center',
// padding: auto;: '4px',
// color: '#000',
// cursor: 'pointer',
// background-color: '#fff',
// margin: '2px',
// width: '50px',
// height: '40',
// border-radius: '2px',
