/* eslint-disable react/prop-types */
import styled from 'styled-components';

export const Center = styled.div`
  background-color: #6700fd; // #6700fd #4f00fd
`;
// margin-left: 50%;
// transform: translateX(-50%);

export const DisplayBar = styled.div`
  position: absolute;
  background-color: #fff;
  left: 8px;
  top: 20%;
  right: 8px;
  bottom: 8px;
  opacity: 0;
  border-radius: 10px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
  z-index: -1;
  overflow: auto;

  &.open {
    top: 8px;
    box-shadow: 6px 0px 10px 4px #0000004f;
    z-index: 300;
    opacity: 1;
  }

  & .close {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 1rem;
    top: 1.5rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: scale(1.2);
    }

    cursor: pointer;
    & svg {
      width: 1.7rem;
      height: 1.7rem;
      fill: #fff;
      pointer-events: none;
    }
  }

  /* @media only screen and (max-width: 50em) {
  } */
`;

export const Bar = styled.div`
  /* width: 800px; //414 */
  /* height: 7rem; */
  text-align: left;
  padding: 2px 5px;
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: 800px;
  width: 100%;

  @media only screen and (max-width: 50em) {
    width: 100%; //414
    height: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props['bottom'] + 'rem'};
  flex-wrap: ${(props) => props['wrap']};
  background-color: #6700fd;
  padding: 1rem;
  border-radius: 4px;
`;

// export const Logo = styled.div`
//   position: absolute;
//   width: 6.4rem;

//   @media only screen and (max-width: 65em) {
//     /* display: none; */
//   }
// `;

export const SearchBar = styled.div`
  position: fixed;
  right: 1rem;
  top: 0.2rem;
  z-index: 200;
  box-shadow: 1px 1px 15px #1f004d59;
  border-radius: 4px;

  & button {
    border: none;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px 8px;
    background-color: #fff;
    margin: 5px 0;
    &:focus {
      outline: none;
      border: none;
    }
    &:active {
      transform: scale(1.1);
    }

    & svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  /* @media only screen and (max-width: 50em) {
    //
  } */
`;

export const RowLink = styled.div`
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const LinkHarpa = styled.a`
  /* background-color: #6700fd; */
  color: #333;
  padding: 5px;
  margin: 3px;
  /* border-radius: 4px; */
  cursor: pointer;
  text-align: center;
  width: ${(props) => props['Width'] + 'rem'};
  height: ${(props) => props['Height']};
  border-bottom: 1px solid #333;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #d9d9d9;
  }

  &:active {
    transform: scale(1.6);
    background-color: #d9d9d9;
  }
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6700fd;
  margin-bottom: 1px;
`;

export const Logo = styled.div`
  width: 3rem;
  @media only screen and (max-width: 65em) {
    /* display: none; */
  }
`;

function ToggleWrapStyled({ children, className, ...rest }) {
  function handleState(e) {
    e.preventDefault();
    const header = document.querySelector('.navbar');
    e.target.classList.toggle('active');
    header.classList.toggle('active');
  }
  return (
    <div className={className} {...rest} onClick={handleState}>
      <div className="toggle-bar" />
    </div>
  );
}

export const ToggleWrap = styled(ToggleWrapStyled)`
  position: fixed;
  top: 1;
  left: 1rem;
  align-self: center;
  justify-self: end;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1); */

  .toggle-bar,
  .toggle-bar::before,
  .toggle-bar::after,
  &.active .toggle-bar,
  &.active .toggle-bar::before,
  &.active .toggle-bar::after {
    transition: all 0.2s ease-in-out;
  }

  & .toggle-bar {
    width: 25px;
    height: 2px;
    position: relative;
    display: block;
    cursor: pointer;
    z-index: 10;
    overflow: initial;
    pointer-events: none;
    background: #fff;
    pointer-events: none;
    user-select: none;
  }

  & .toggle-bar::before {
    content: '';
    display: block;
    background: #fff;
    height: 2px;
    width: 25px;
    position: absolute;
    top: 6px;
    transform: rotate(0deg);
    transform-origin: -1px;
  }

  & .toggle-bar::after {
    content: '';
    display: block;
    background: #fff;
    height: 2px;
    width: 25px;
    position: absolute;
    top: -6px;
    transform: rotate(0deg);
    transform-origin: -1px;
  }

  &.active .toggle-bar::before {
    transform-origin: 5px -1px;
    transform: rotate(-45deg);
  }

  &.active .toggle-bar::after {
    transform-origin: 5px 3px;
    transform: rotate(45deg);
  }

  &.active .toggle-bar {
    height: 0px;
    transform: none;
  }

  @media only screen and (max-width: 50em) {
    //
  }
`;

export const NavBar = styled.div`
  position: absolute;
  top: 110%;
  left: -100%;
  z-index: 9999;
  background-color: #fff;
  color: #333;
  display: flex;
  flex-direction: column;
  width: 20rem;
  border-radius: 4px;
  box-shadow: 1px 1px 10px #3333332b;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &.active {
    left: 8px;
  }

  & a {
    padding: 1rem;
    display: flex;
    align-items: center;
    & svg {
      color: #333;
      margin-right: 5px;
    }

    & span {
      margin-top: 3px;
      color: #333;
    }
    &:hover {
      background-color: #e3e0e0;
    }
  }
`;
